<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Use the following solubility graph to answer the questions below.</p>

      <p class="mb-3 pl-8">
        <img src="/img/assignments/solubilityVsTemp.png" style="max-width: 75%; height: auto" />
      </p>

      <p class="mb-2">
        a) A solution of potassium chlorate,
        <chemical-latex content="KClO3," />
        has 20 grams of the salt dissolved in 100 grams of water at
        <number-value :value="T1" unit="^\circ\text{C.}" />
        Approximately how many more grams of the salt can be added to the solution before reaching
        the saturation point?
      </p>

      <calculation-input
        v-model="inputs.mass1"
        class="mb-5"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) At approximately what temperature does the solubility of sodium chloride,
        <chemical-latex content="NaCl," />
        match that of
        <chemical-latex :content="salt" />
      </p>

      <calculation-input
        v-model="inputs.temp1"
        class="mb-5"
        prepend-text="$\text{Temperature:}$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Which salt is <b>LEAST</b> soluble at
        <stemble-latex content="$50^\circ\text{C?}$" />
      </p>

      <v-radio-group v-model="inputs.leastSoluble" class="mb-0 mt-0" :disabled="!allowEditing">
        <v-radio v-for="(molecule, i) in molecules" :key="i" class="pl-8 mb-1 mt-0" :value="i">
          <template #label>
            <chemical-latex style="font-size: 14px" :content="molecule" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-n2">
        d) A beaker containing 80 grams of lead(II) nitrate,
        <chemical-latex content="Pb(NO3)2," />
        in 100 grams of water has a temperature of
        <number-value :value="T2" unit="^\circ\text{C.}" />
        Approximately how many grams of the salt would be undissolved on the bottom of the beaker?
      </p>

      <calculation-input
        v-model="inputs.mass2"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question405',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mass1: null,
        temp1: null,
        leastSoluble: null,
        mass2: null,
      },
      salt1options: ['NaNO3', 'CaCl2'],
      salt2options: ['NaCl', 'KNO3'],
      salt3options: ['KCl', 'K2Cr2O7'],
      salt4options: ['KClO3', 'Ce2(SO4)3'],
    };
  },
  computed: {
    T1() {
      return this.taskState.getValueBySymbol('T1').content;
    },
    T2() {
      return this.taskState.getValueBySymbol('T2').content;
    },
    saltVersion() {
      return this.taskState.getValueBySymbol('saltVersion').content;
    },
    salt() {
      if (this.saltVersion.value === 1) {
        return 'potassium dichromate, K2Cr2O7?';
      } else if (this.saltVersion.value === 2) {
        return 'potassium nitrate, KNO3?';
      } else if (this.saltVersion.value === 3) {
        return 'potassium chloride, KCl?';
      } else {
        return 'potassium chlorate, KClO3?';
      }
    },
    mcOptionV1() {
      return this.taskState.getValueBySymbol('mcOptionV1').content;
    },
    mcOptionV2() {
      return this.taskState.getValueBySymbol('mcOptionV2').content;
    },
    mcOptionV3() {
      return this.taskState.getValueBySymbol('mcOptionV3').content;
    },
    mcOptionV4() {
      return this.taskState.getValueBySymbol('mcOptionV4').content;
    },
    molecules() {
      return [
        this.salt1options[this.mcOptionV1.value - 1],
        this.salt2options[this.mcOptionV2.value - 1],
        this.salt3options[this.mcOptionV3.value - 1],
        this.salt4options[this.mcOptionV4.value - 1],
      ];
    },
  },
};
</script>
